<template>
  <div class="flex flex-wrap">
    <DynamicAuctionItemListCarParameter
      v-if="firstRegistration"
      class="lg:w-1/2"
      :title="firstRegistration.name"
      :value="firstRegistration.value"
    >
      <i class="ab-icon ab-icon-date h-8"></i>
    </DynamicAuctionItemListCarParameter>

    <ElementsCarMileageParameters class="lg:w-1/2" :car="car" />

    <DynamicAuctionItemListCarParameter
      v-if="power"
      :title="powerTitle"
      class="lg:order-2 lg:w-1/2"
      :value="power"
    >
      <div class="flex justify-center">
        <i class="ab-icon ab-icon-performance h-8"></i>
      </div>
    </DynamicAuctionItemListCarParameter>

    <DynamicAuctionItemListCarParameter
      v-if="amountOfOwners"
      class="lg:order-4 lg:w-1/2"
      :title="amountOfOwners.name"
      :value="amountOfOwners.value"
    >
      <i class="ab-icon ab-icon-owner h-8"></i>
    </DynamicAuctionItemListCarParameter>
  </div>
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import { getFirstRegistration } from '@autobid/ui/utils/car/getFirstRegistration'
import { useCarPower } from '@autobid/ui/composables/car/useCarPower'
import { getAmountOfOwners } from '@autobid/ui/utils/car/getAmountOfOwners'

type Props = {
  car: AuctionCar
}

const props = defineProps<Props>()

const firstRegistration = computed(() =>
  getFirstRegistration(props.car.equipments)
)
const amountOfOwners = getAmountOfOwners(props.car.equipments)
const { powerTitle, getPower } = useCarPower()
const power = getPower(props.car.equipments)
</script>
